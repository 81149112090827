<template>
  <div class="d-flex flex-column overflow-auto vh-100">
    <edit-maintenance-contractors id="edit-maintenance-contractor" :maintenanceContractors="selectedMaintenanceContractor" />
    <sticky-header :title="$route.meta.title">
      <template #buttons>
        <b-button class="ml-2" variant="primary" :to="{name: 'maintenance.contractors.create'}"><fa-icon class="mr-2" icon="plus" />Create</b-button>
      </template>
    </sticky-header>
    <div class="flex-fill p-3">
      <b-row class="mb-3">
        <b-col cols="12">
          <b-form-input debounce="500" @update="load" placeholder="Search" type="text" v-model="query" />
        </b-col>
      </b-row>
      <div class="d-flex flex-column mt-5 py-3 justify-content-center" v-if="loading">
        <b-spinner class="align-self-center" variant="primary" />
        <p class="align-self-center mt-1 mb-0">Loading...</p>
      </div>
      <b-row>
        <b-col cols="12">
          <b-list-group class="mb-4" v-if="!loading">
            <b-table sortable responsive outlined :items="formattedData" :fields="['Location/Property', 'name', 'user_name', 'actions']">
              <template #cell(actions)="row">
                <div class="align-self-center d-flex flex-md-row flex-column">
                  <b-button class="mb-1 mb-md-0 ml-md-1" @click.prevent="edit(row.item.id)" variant="primary" v-b-popover.top.hover="`Edit`"><fa-icon icon="pen" /></b-button>
                  <b-button class="mb-1 mb-md-0 ml-md-1" @click.prevent="view(row.item.id)" variant="primary" v-b-popover.top.hover="`View`"><fa-icon icon="eye" /></b-button>
                </div>
              </template>
            </b-table>
          </b-list-group>
          <b-pagination-nav :link-gen="paginationLinkGen" :number-of-pages="lastPage" use-router v-if="lastPage > 1" />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'
import EditMaintenanceContractors from './Modals/EditMaintenanceContractor.vue'
import StickyHeader from '@/components/StickyHeader.vue'
import { mapActions, mapState } from 'vuex'
import map from "lodash/map";

export default {
  components: { EditMaintenanceContractors, StickyHeader },
  data() {
    return {
      query: '',
      selectedMaintenanceContractor: {},
      formattedData: []
    };
  },
  async mounted() {
    if (this.$route.meta.modalId) {
      this.$bvModal.show(this.$route.meta.modalId)
    }

    await this.load();
  },
  computed: {
    ...mapState('maintenance', ['data', 'loading', 'lastPage']),
  },
  methods: {
    ...mapActions('maintenance', ['fetch']),
    paginationLinkGen(pageNo) {
      return pageNo === 1
        ? {name: 'maintenance.complete', query: pickBy({...this.$route.params, page: null}, identity)}
        : {name: 'maintenance.complete', query: {...this.$route.params, page: pageNo}};
    },
    edit(id) {
      this.selectedMaintenanceContractor = this.data.find(c => c.id === id);

      if (!(this.$route.name === 'maintenance.update' && this.$route.params.maintenanceId === id)) {
        this.$router.push({ name: 'maintenance.update', params: { maintenanceId: id } });
      }
    },
    view(id) {
      this.$router.push({ name: 'maintenance.details', params: { maintenanceId: id } });
    },
    async load() {
      return await this.fetch({
        query: this.query,
        page: this.$route.query.page || 1,
        complete: true
      });
    },
  },
  watch: {
    async '$route'(newValue, value) {
      if (!value.meta.modalId && newValue.meta.modalId) {
        return this.$bvModal.show(newValue.meta.modalId);
      }

      if (newValue.name === value.name && newValue.query.page !== value.query.page) {
        await this.load();
      }

      return this.$bvModal.hide(value.meta.modalId);
    },
    data(data) {
      this.formattedData = map(data, maintenance => ({
        id: maintenance.id,
        'Location/Property': maintenance?.property?.name ?? 'Unknown',
        name: maintenance.summary,
        description: maintenance.description,
        user_name: maintenance.user.name
      }))
    }
  }
}
</script>

<style>

</style>
